<template>
  <div v-show="!isFilterActive" class="annotate_container">
    <div v-if="isFetchedTransactionToAnnotate && hasTransactionToAnnotate" class="annotate_line">
      <div class="annotate_text">
        <span>{{ unlockedTransactions }}</span>
        {{ annotationsText }}
      </div>
      <nuxt-link :to="toAnnotateLink">
        <uds-main-button
          class="annotate_button_cta"
          size="small"
          icon-right
          icon-name="arrow_right_alt"
          >Annoter</uds-main-button
        >
      </nuxt-link>
    </div>
    <div v-else-if="!isFetchedTransactionToAnnotate">
      <!-- <TextPlaceholder :height="15" :width="450" /> -->
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  source: "bank" | "dashboard" | "cash" | "purchase" | "personal" | "sales";
}>();

const unlockedStore = useUnlockedStore();
const bankStore = useBankStore();
const purchasesStore = usePurchasesStore();
const salesStore = useSalesStore();
const societyStore = useSocietyStore();
const isAccruals = computed(() => societyStore.isAccruals);

const unlockedTransactions = computed(() => {
  switch (props.source) {
    case "bank":
      return unlockedStore.bankDocument;
    case "cash":
      return unlockedStore.cashDocument;
    case "purchase":
      return unlockedStore.purchaseDocument;
    case "personal":
      return unlockedStore.personalDocument;
    case "sales":
      return unlockedStore.saleDocument;
    case "dashboard":
      return unlockedStore.allDocument;
    default:
      return null;
  }
});

const hasTransactionToAnnotate = computed(
  () => isDefined(unlockedTransactions.value) && unlockedTransactions.value > 0,
);

const isFetchedTransactionToAnnotate = computed(() => isDefined(unlockedTransactions.value));

const isFilterActive = computed(() => {
  switch (props.source) {
    case "dashboard":
      return false;
    case "purchase":
      return purchasesStore.queryProperties.toAnnotate;
    case "sales":
      return salesStore.queryProperties.toAnnotate;
    default:
      return bankStore.queryProperties.toAnnotate;
  }
});

const annotationsText = computed(() => {
  if (unlockedTransactions.value) {
    switch (props.source) {
      case "purchase":
      case "personal":
      case "sales":
        if (unlockedTransactions.value > 1) {
          return "Factures à annoter";
        } else {
          return "Facture à annoter";
        }
      default:
        if (unlockedTransactions.value > 1) {
          return "Opérations à annoter";
        } else {
          return "Opération à annoter";
        }
    }
  }
  return "";
});

const toAnnotateLink = computed(() => {
  if (props.source === "bank" || (props.source === "dashboard" && unlockedStore.bankDocument > 0)) {
    const link = useBuildRouteFromQueryPropertiesComposable(
      defaultBankQueryProperties.value,
      {
        ...bankStore.queryProperties,
        idBankAccount: [],
        transactionId: null,
        checkedIds: [],
        toAnnotate: true,
        startDate: "1970-01-01",
        endDate: "2170-12-31",
        page: 1,
        tab: "bankStatements",
      },
      "/banks",
    );
    return `${link}${props.source === "dashboard" ? "#fromDashboard" : ""}`;
  } else if (
    props.source === "cash" ||
    (props.source === "dashboard" && unlockedStore.cashDocument > 0)
  ) {
    const link = useBuildRouteFromQueryPropertiesComposable(
      defaultBankQueryProperties.value,
      {
        ...bankStore.queryProperties,
        idBankAccount: [],
        checkedIds: [],
        toAnnotate: true,
        startDate: "1970-01-01",
        endDate: "2170-12-31",
        page: 1,
        tab: "cash",
      },
      "/banks",
    );
    return `${link}${props.source === "dashboard" ? "#fromDashboard" : ""}`;
  } else if (
    props.source === "purchase" ||
    (props.source === "dashboard" && unlockedStore.purchaseDocument > 0)
  ) {
    const link = useBuildRouteFromQueryPropertiesComposable(
      defaultPurchasesQueryProperties.value,
      {
        ...purchasesStore.queryProperties,
        itemId: null,
        checkedIds: [],
        toAnnotate: true,
        startDate: "1970-01-01",
        endDate: "2170-12-31",
        page: 1,
        tab: "invoices",
      },
      "/purchases",
    );
    return `${link}`;
  } else if (
    props.source === "personal" ||
    (props.source === "dashboard" && unlockedStore.personalDocument > 0)
  ) {
    if (isAccruals.value) {
      const link = useBuildRouteFromQueryPropertiesComposable(
        defaultPurchasesQueryProperties.value,
        {
          ...purchasesStore.queryProperties,
          itemId: null,
          checkedIds: [],
          toAnnotate: true,
          startDate: "1970-01-01",
          endDate: "2170-12-31",
          page: 1,
          tab: "expensenotes",
        },
        "/purchases",
      );
      return `${link}`;
    } else {
      const link = useBuildRouteFromQueryPropertiesComposable(
        defaultBankQueryProperties.value,
        {
          ...bankStore.queryProperties,
          idBankAccount: [],
          checkedIds: [],
          transactionId: null,
          toAnnotate: true,
          startDate: "1970-01-01",
          endDate: "2170-12-31",
          page: 1,
          tab: "expenseNotes",
        },
        "/purchases/expense-notes",
      );
      return `${link}`;
    }
  } else if (
    props.source === "sales" ||
    (props.source === "dashboard" && unlockedStore.saleDocument > 0)
  ) {
    const link = useBuildRouteFromQueryPropertiesComposable(
      defaultSalesQueryProperties.value,
      {
        ...salesStore.queryProperties,
        itemId: null,
        checkedIds: [],
        toAnnotate: true,
        startDate: "1970-01-01",
        endDate: "2170-12-31",
        page: 1,
      },
      "/sales",
    );
    return `${link}`;
  } else {
    $notifier().open({ type: "error", content: "Aucun lien trouvé" });
    return "/error";
  }
});
</script>

<style lang="scss" scoped>
.annotate_container {
  display: flex;
  align-items: center;
  max-width: 550px;
  width: 100%;
  background-color: $uds-primary-50;
  border-radius: $uds-radius-2;
  font-style: normal;
  font-weight: $uds-weight-500;

  .annotate_line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    padding: $uds-spacing-1_5 22px;

    .annotate_text {
      display: flex;
      // @extend .ds-text-title;
      color: $uds-neutral-900;
      line-height: 0;
      align-items: center;
      font-size: 20px;

      span {
        // @extend .ds-content-medium;
        // line-height: 0;
        margin-right: 10px;
        color: $uds-primary-500;
        font-size: 25px;
        margin-bottom: 2px;
      }
    }
  }
}
</style>
